document.addEventListener("DOMContentLoaded", function () {
    let page = ajax_params.start_page;
    let loading = false;
    const postsContainer = document.getElementById("media-posts-container");

    async function loadMorePosts() {
        if (loading) return;
        loading = true;

        try {
            let response = await fetch(ajax_params.ajax_url, {
                method: "POST",
                headers: { "Content-Type": "application/x-www-form-urlencoded" },
                body: new URLSearchParams({
                    action: "_mm_load_more_media_posts",
                    paged: page
                })
            });

            let data = await response.text();
            if (data.trim() !== "No more posts") {
                postsContainer.insertAdjacentHTML("beforeend", data);
                page++;
                loading = false;
            } else {
                window.removeEventListener("scroll", handleScroll);
            }
        } catch (error) {
            console.error("Error loading posts:", error);
        }
    }

    function handleScroll() {
        if (window.innerHeight + window.scrollY >= document.body.offsetHeight / 2) {
            loadMorePosts();
        }
    }

    window.addEventListener("scroll", handleScroll);
});
